import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'edit_circle_fill_blue_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="edit_circle_fill_blue_28"><linearGradient id="edit_circle_fill_blue_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><rect fill="url(#edit_circle_fill_blue_28_a)" height="28" rx="14" width="28" /><path clip-rule="evenodd" d="M14.987 9.976l3.035 3.035-5.539 5.54a5.722 5.722 0 01-2.375 1.426l-2.488.76a.286.286 0 01-.358-.358l.76-2.488a5.721 5.721 0 011.427-2.375zm4.17-2.35l1.216 1.217c.47.468.5 1.209.094 1.714l-.094.104-1.516 1.516-3.035-3.035 1.517-1.515a1.286 1.286 0 011.819 0z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28EditCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28EditCircleFillBlue: FC<Icon28EditCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28EditCircleFillBlue as any).mountIcon = mountIcon;

export default Icon28EditCircleFillBlue;
