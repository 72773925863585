import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'list_add_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="list_add_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M14 11c0-.552-.456-1-1.002-1H3.002A.999.999 0 002 11c0 .552.456 1 1.002 1h9.996A.999.999 0 0014 11zm0-4c0-.552-.456-1-1.002-1H3.002A.999.999 0 002 7c0 .552.456 1 1.002 1h9.996A.999.999 0 0014 7zm4 7v-2.99a1 1 0 10-2 0V14h-2.99a1 1 0 100 2H16v2.99a1 1 0 102 0V16h2.99a1 1 0 100-2H18zM2 15c0 .552.453 1 .997 1h6.006c.55 0 .997-.444.997-1 0-.552-.453-1-.997-1H2.997A.996.996 0 002 15z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ListAddProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ListAdd: FC<Icon24ListAddProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ListAdd as any).mountIcon = mountIcon;

export default Icon24ListAdd;
