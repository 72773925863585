import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'fullscreen_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="fullscreen_16"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.22 5.72a.75.75 0 001.06 1.06l2.225-2.221V6.25a.75.75 0 001.5 0v-3.5a.75.75 0 00-.75-.75H9.75a.75.75 0 100 1.5h1.692L9.22 5.72zm-2.44 4.56a.75.75 0 00-1.06-1.06l-2.224 2.22V9.75a.75.75 0 10-1.5 0v3.5a.75.75 0 00.75.75H6.25a.75.75 0 000-1.5H4.558l2.221-2.22z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16FullscreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Fullscreen: FC<Icon16FullscreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Fullscreen as any).mountIcon = mountIcon;

export default Icon16Fullscreen;
