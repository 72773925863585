import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'lock_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="lock_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M12 13a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1z" /><path d="M18.77 8.525c-.578-.295-1.23-.373-1.87-.403V7a4.9 4.9 0 10-9.8 0v1.122c-.64.03-1.292.108-1.87.403a3.9 3.9 0 00-1.705 1.704c-.239.47-.336.971-.381 1.527-.044.537-.044 1.198-.044 2.006v2.476c0 .808 0 1.469.044 2.006.045.556.142 1.058.381 1.527a3.9 3.9 0 001.704 1.704c.47.239.971.336 1.527.381.537.044 1.198.044 2.006.044h6.476c.808 0 1.469 0 2.006-.044.556-.045 1.058-.142 1.527-.381a3.9 3.9 0 001.704-1.704c.239-.47.336-.971.381-1.527.044-.537.044-1.198.044-2.006v-2.476c0-.808 0-1.469-.044-2.006-.045-.556-.142-1.058-.381-1.527a3.9 3.9 0 00-1.704-1.704zM6.904 9.938c-.445.036-.684.103-.856.19a2.1 2.1 0 00-.918.919c-.088.172-.155.411-.191.856-.037.455-.038 1.042-.038 1.897v2.4c0 .855 0 1.442.038 1.897.036.445.103.683.19.856a2.1 2.1 0 00.919.918c.172.088.411.155.856.191.455.037 1.042.038 1.897.038h6.4c.855 0 1.442 0 1.897-.038.445-.036.683-.103.856-.19a2.1 2.1 0 00.918-.919c.088-.173.155-.411.191-.856.037-.455.038-1.042.038-1.897v-2.4c0-.855 0-1.442-.038-1.897-.036-.445-.103-.684-.19-.856a2.1 2.1 0 00-.919-.918c-.172-.088-.411-.155-.856-.191C16.642 9.9 16.055 9.9 15.2 9.9H8.8c-.855 0-1.442 0-1.897.038zM15.1 7a3.1 3.1 0 10-6.2 0v1.1h6.2z" /><path d="M13 14a1 1 0 10-2 0v2a1 1 0 102 0z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24LockOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24LockOutline: FC<Icon24LockOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24LockOutline as any).mountIcon = mountIcon;

export default Icon24LockOutline;
