import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'lock_open_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="lock_open_outline_24"><path d="M12 13a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M7.1 7a4.9 4.9 0 019.395-1.954.9.9 0 11-1.65.718 3.082 3.082 0 00-.159-.314A3.1 3.1 0 008.9 7v1.1h6.338c.808 0 1.469 0 2.006.044.556.045 1.058.142 1.527.381a3.9 3.9 0 011.704 1.704c.239.47.336.971.381 1.527.044.537.044 1.198.044 2.006v2.476c0 .808 0 1.469-.044 2.006-.045.556-.142 1.058-.381 1.527a3.9 3.9 0 01-1.704 1.704c-.47.239-.971.336-1.527.381-.537.044-1.198.044-2.006.044H8.762c-.808 0-1.469 0-2.006-.044-.556-.045-1.058-.142-1.526-.381a3.9 3.9 0 01-1.705-1.704c-.239-.47-.336-.971-.381-1.527-.044-.537-.044-1.198-.044-2.006v-2.476c0-.808 0-1.469.044-2.006.045-.556.142-1.058.381-1.527A3.9 3.9 0 015.23 8.525c.468-.239.97-.336 1.526-.381.11-.01.224-.016.344-.022V7zm-.197 2.938c-.445.036-.683.103-.856.19a2.1 2.1 0 00-.918.919c-.088.172-.155.411-.191.856-.037.455-.038 1.042-.038 1.897v2.4c0 .855 0 1.442.038 1.897.036.445.103.683.191.856a2.1 2.1 0 00.918.918c.173.088.411.155.856.191.455.037 1.042.038 1.897.038h6.4c.855 0 1.442 0 1.897-.038.445-.036.684-.103.856-.19a2.1 2.1 0 00.918-.919c.088-.172.155-.411.191-.856.037-.455.038-1.042.038-1.897v-2.4c0-.855 0-1.442-.038-1.897-.036-.445-.103-.684-.19-.856a2.1 2.1 0 00-.919-.918c-.172-.088-.411-.155-.856-.191C16.642 9.9 16.055 9.9 15.2 9.9H8.8c-.855 0-1.442 0-1.897.038z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24LockOpenOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24LockOpenOutline: FC<Icon24LockOpenOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24LockOpenOutline as any).mountIcon = mountIcon;

export default Icon24LockOpenOutline;
